export const isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    if ($('#homepage').length) {
        viewportBottom += 1400;
    }

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

export const addToAnyInViewport = function () {
    let executed = false;
    return function () {
        if (!executed) {
            executed = true;
            $('footer').after('<script async src="https://static.addtoany.com/menu/page.js"></script>');
        }
    };
}();